import React from "react";

import "./Footer.scss";

import LinkedIn from "../../assets/linkedIn.png";
import Twitter from "../../assets/twitter.png";
import Dribble from "../../assets/dribble.png";



const Footer = () => {
	return (
		<footer className="row align-items-center">
			<p className="header">Contact :</p>
			<span>
				<a href="mailto:me@ajirioma.com">me@ajirioma.com</a>
				<p>I look forward to talking about new projects!</p>
			</span>
			<div className="row align-items-center footer_social justify-content-between">
				<a
					className="linkedIn"
					href="https://www.linkedin.com/in/ajiri-omafokpe/"
					target="_blank"
					rel='noopener noreferrer'
				>
					<img src={LinkedIn} alt="LinkedIn Link" />
				</a>
				<a
					className="twitter"
					href="https://twitter.com/AjiriOma"
					target="_blank"
					rel='noopener noreferrer'
				>
					<img src={Twitter} alt="Twitter Link" />
				</a>
				<a
					className="dribble"
					href="https://dribbble.com/ajiriomafokpe"
					target="_blank"
					rel='noopener noreferrer'
				>
					<img src={Dribble} alt="Dribble Link" />
				</a>
			</div>
		</footer>
	);
};

export default Footer;
