import React from 'react'
import { Link } from "react-router-dom";

import "./MorenaLeaderScreen.scss";

import Morena3 from "../../assets/morena3.png";

import Task1 from "../../assets/task1.svg";
import Task2 from "../../assets/task2.svg";
import Task3 from "../../assets/task3.svg";

import Accc1 from "../../assets/accc1.svg";
import Settings1 from "../../assets/settings1.svg";
import Settings2 from "../../assets/settings2.svg";
import Hanging1 from "../../assets/hanging1.svg";
import Hanging2 from "../../assets/hanging2.svg";


const MorenaLeaderScreen = () => {
  return (
    <div className="morenaLeader">
			<div className="container">
				<div className="morenaLeader-cont">
					<Link className="row morenaLeader-back" to="/">
						<img src="BG.png" alt="Back Button" />
						<p>Back</p>
					</Link>
					<div className="row morenaLeader-details">
						<div className="details-overlay"></div>
						<div className="details-intro">
							<h3>MorenaPixels Design Leadership Process</h3>
							<div>
								<p>
                Building An inclusive and diverse stock media platform focused on making stock
								</p>
								<p>content containing people of color more accessible.</p>
							</div>

							<div className="details-img funMob">
							<div className="details-img-overlay"></div>
              <img src={Morena3} alt="Morena Illustration" />
						</div>

            <p>
								<b>See Web App </b>
								<a href="http://creators.morenapixels.com" target="_blank" rel='noopener noreferrer'>
									{" "}
									here
								</a>
							</p>

							<p>
								<b>Project Date:</b> Jan 2021 - Date
							</p>

							<div>
								<p>
									<b>Company:</b> MorenaPixels
								</p>
								<p className="prod">
                Working part time as the lead product designer, with a team of seven; 3 designers, 4 developers, 1 Project Manager, and 1 Project Owner
								</p>
							</div>

							<div>
								<p>
									<b>Collaborators</b>
								</p>
								<p>
                Ajiri Omafokpe, Samuel Aneme, Meyene Udoh, Remi Owadokun, Samuel Olaegbe
								</p>
							</div>

							<p>
								<b>Platform:</b>  Desktop web, Mobile Web
							</p>

							<p>
								<b>My Contribution:</b> Research, conceptualization, UI Design, UX design, Project Ownership, prototyping and leadership of 3 other designers. 

							</p>
						</div>
						<div className="details-img funDes">
							<div className="details-img-overlay"></div>
							<img src={Morena3} alt="Morena Illustration" />
						</div>
					</div>
				</div>
			</div>
			<section className="morenaLeader-bg bg-1">
				<div className="container row bg-cont">
					<p className="bg-cont-header">Background;</p>
					<div>
           
						<p>
            Morena Pixels is a Stock Media Platform making a diversity of image, video, and
						</p>
						<p>
            audio content of Africa and people of color accessible to the rest of the world.
						</p>
				
						<p className="bg-cont-between">
            Founded in 2021, MorenaPixels aims to have an unmatched, extensive selection of
						</p>
						<p>
            different photos and videos from different parts of the world to help content
						</p>
						<p>creators, experts, designers, thought leaders, journalists, and other professionals</p>
						<p>across the globe. </p>
					</div>
				</div>
			</section>

			<section className="morenaLeader-bg bg-2">
				<div className="container row bg-cont">
					<p className="bg-cont-header">Research results;</p>
					<div className="bg-2-ul">
            <ul>
              <li>
                <p><b>40%</b> of the participants said that they would like to have multiple payment options, like crypto. </p>
              </li>

              <li>
                <p><b>60%</b> of the participants wanted to be able to earn on referrals and have some control over the process </p>
              </li>

              <li>
                <p><b>10%</b> of the participants wanted to be able to take a pay cut so their content can be on the front pages when customers search (A pay for ads situation). </p>
              </li>

              <li>
                <p><b>30%</b> of the participants said they would like to choose if they want to sell the content for a one-off fee or get royalties. </p>
              </li>

              <li>
                <p><b>20%</b> of the participants wanted there to be quick support for when they have problems using the platform or getting paid. </p>
              </li>
            </ul>
					</div>
				</div>
			</section>

			<section className="morenaLeader-bg bg-3">
				<div className="container row bg-cont">
					<p className="bg-cont-header">Task co-ordination;</p>
					<div className="ging">
            <img className="mia mi1" src={Task1} alt="Task co-ordination screen" />
            <img className="mia mi2" src={Task2} alt="Task co-ordination screen" />
            <img className="mia mi3" src={Task3} alt="Task co-ordination screen" />
					</div>
				</div>
			</section>

      <section className="morenaLeader-bg bg-3b">
				<div className="container row bg-cont">
					<p className="bg-cont-header">Acceptance criteria;</p>
					<div className="ging1">
            <img className="mia mi4" src={Accc1} alt="Acceptance criteria screen" />
					</div>
				</div>
			</section>

			<section className="morenaLeader-bg bg-5">
				<div className="container row bg-cont">
					<p className="bg-cont-header">Setting up Figma;</p>
					<div className="ging2">
            <img className="mia mi5" src={Settings1.svg} alt="Setting up Figma screen" />
            <img className="mia bottom" src={Settings2.svg} alt="Setting up Figma screen" />
					</div>
				</div>
			</section>

      <section className="morenaLeader-bg bg-5b">
				<div className="container row bg-cont">
					<p className="bg-cont-header">Handing off to developers;</p>
					<div className="ging3">
            <img className="mia mi6" src={Hanging1} alt="Handing off to developers screen" />
            <img className="mia bottom" src={Hanging2} alt="Handing off to developers screen" />
					</div>
				</div>
			</section>

			<section className="morenaLeader-bg bg-6">
				<div className="container row bg-cont">
					<p className="bg-cont-header">Reflection;</p>
					<div  className="disable">
            <ul>
              <li><p>As a team lead, you need to listen and be very approachable</p></li>
              <li><p>Humans have unpredictable human problems</p></li>
              <li><p>Humans have unpredictable human problems</p></li>
              <li><p>Plan for unforseen problems</p></li>
              <li><p>Be as clear as possible when giving direction and feedback</p></li>
            </ul>
					</div>
				</div>
			</section>
		</div>
  )
}

export default MorenaLeaderScreen