import React from "react";
import { Link } from "react-router-dom";

import "./YellowScreen.scss";

import Yellow3 from "../../assets/yellow3.png";
import Wallets from "../../assets/Wallets.png";
import Notifications from "../../assets/Notifications.png";
import Withdrawal from "../../assets/Withdrawal.png";


const YellowScreen = () => {
	return (
		<div className="yellowScreen">
			<div className="container">
				<div className="yellowScreen-cont">
					<Link className="row yellowScreen-back" to="/">
						<img src="BG.png" alt="Back Button" />
						<p>Back</p>
					</Link>
					<div className="row yellowScreen-details">
						<div className="details-overlay"></div>
						<div className="details-intro">
							<h3>Yellow Card Mobile App</h3>
							<div>
								<p>
									Building version 1.0 of the Yellow Card mobile app, one of
									Africa’s fastest
								</p>
								<p>growing cryptocurrency startups.</p>
							</div>

							<div className="details-img funMob">
							<div className="details-img-overlay"></div>
							<img src={Yellow3} alt="Yellowcard Illustration" />
						</div>

							<p>
								<a
									href="https://play.google.com/store/apps/details?id=io.yellowcard.app&hl=en&gl=US"
									target="_blank"
									rel='noopener noreferrer'
								>
									<b>Get the App</b>
								</a>
							</p>

							<p>
								<b>Project Date:</b> August 2020 - March 2021
							</p>

							<div>
								<p>
									<b>Company:</b> Yellow Card Financial
								</p>
								<p>
									Working remotely as a product designer, with a team of seven;
									3 designers, 3 developers, and 1 Project manager
								</p>
							</div>

							<div>
								<p>
									<b>Collaborators</b>
								</p>
								<p>
									Great Ndidi, Eniola Abiola, Ajiri Omafokpe, Idorenyin Obong,
									Peter Esenwa, Joe Okpukoro, Bukunmi Folorunso
								</p>
							</div>

							<p>
								<b>Platform:</b> IOS, Android
							</p>

							<p>
								<b>My Contribution:</b> Visual design,Experience Design,
								Information architecture design
							</p>
						</div>
						<div className="details-img funDes">
							<div className="details-img-overlay"></div>
							<img src={Yellow3} alt="Yellowcard Illustration" />
						</div>
					</div>
				</div>
			</div>
			<section className="yellowScreen-bg bg-1">
				<div className="container row bg-cont">
					<p className="bg-cont-header">Background;</p>
					<div>
						<p>
							Yellow Card is a Binance-backed cryptocurrency exchange startup
							operating out
						</p>
						<p>
							of Nigeria, with a growing presence in countries across Africa.
							The company’s
						</p>
						<p>
							main value proposition is making crypto more accessible in
							developing countries,
						</p>
						<p>
							and it does this by allowing customers to buy crypto with digital
							payments on
						</p>
						<p>their website or cash at their brick and mortar outlets.</p>

						<p className="bg-cont-between">
							The Yellow Card MVP was launched in 2018 as a web app, built by
							the founders
						</p>
						<p>
							themselves, but with a growing user base and increasing mobile
							traffic, the need
						</p>
						<p>to launch a mobile app was larger than ever.</p>
					</div>
				</div>
			</section>

			<section className="yellowScreen-bg bg-2">
				<div className="container row bg-cont">
					<p className="bg-cont-header">The Goal;</p>
					<div>
						<p>
							With the current complexity of the web app, the product largely
							attracts expert
						</p>
						<p>
							traders. User analytics data from 2019-2020 also revealed that 92%
							of customers
						</p>
						<p>
							access the app from mobile devices. With this knowledge, the team
							decided to
						</p>
						<p>
							build an app that is tailor-made for the mobile experience and
							intuitive enough to
						</p>
						<p>allow even beginners to trade.</p>
					</div>
				</div>
			</section>

			<section className="yellowScreen-bg bg-3">
				<div className="container row bg-cont">
					<p className="bg-cont-header">Solution;</p>
					<div>
						<p>
							Because we had an existing redesigned and redeveloped webapp to
							piggyback
						</p>
						<p>
							off, it was easier to draft general requirements for the app. The
							lead designer
						</p>
						<p>
							majorly handled communications with the shareholders to make sure
							we weren't
						</p>
						<p>working out of business scope.</p>

						<p className="bg-cont-between">
							Data research on how new users interacted with our current webapp
							gave us
						</p>
						<p>
							insights into how the UX flows should be modified to accommodate
							new traders.
						</p>
						<p>
							We streamlined the sign-up process in order to reduce onboarding
							drop-off,
						</p>
						<p>
							reduced the number of steps required to make individual
							transactions like making
						</p>
						<p>
							deposits or buying coins, and generally made the product more
							intuitive for new
						</p>
						<p>traders.</p>
					</div>
				</div>
			</section>

			<section className="yellowScreen-bg bg-4">
				<div className="container ">
					<div className="bg-cont-between screen-img">
						<p>Wallet/Trade</p>
						<img src={Wallets} alt="Yellowcard Website Image" />
					</div>

					<div className="bg-cont-between-img screen-img">
						<p>Transactions</p>
						<img src={Notifications} alt="Yellowcard Website Image" />
					</div>
					<div className="bg-cont-between-img screen-img">
						<p>Withdraw to bank account</p>
						<img src={Withdrawal} alt="Yellowcard Website Image" />
					</div>
				</div>
			</section>

			<section className="yellowScreen-bg bg-5">
				<div className="container row bg-cont">
					<p className="bg-cont-header">Results;</p>
					<div>
						<p>
							Since the app was launched, we have since expanded into 4 more
							African
						</p>
						<p>
							countries and are currently set to launch in other countries over
							the course of the
						</p>
						<p>
							year. Data is still being gathered, bug fixes, feature updates
							here and there but
						</p>
						<p>overall, we have built a great product.</p>

						<p className="bg-cont-between">
							I will be back here to update you on some new metrics about how
							customers
						</p>
						<p>
							interact with the app, how it helped meet business goals and
							improvements that
						</p>
						<p>have been made.</p>
					</div>
				</div>
			</section>

			<section className="yellowScreen-bg bg-6">
				<div className="container row bg-cont">
					<p className="bg-cont-header">Reflection;</p>
					<div  className="disable">
						<p>
							This project had me think a lot about marketing and how product
							design can help
						</p>
						<p>
							the business meet its business needs while centering the user.
							Every design
						</p>
						<p>
							decision was made with the user at the center, with business,
							legal and
						</p>
						<p>compliance needs surrounding it.</p>

					</div>
				</div>
			</section>
		</div>
	);
};

export default YellowScreen;
