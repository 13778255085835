import React from "react";

import "./About.scss";

import White from "../../assets/white.png";
import Pink from "../../assets/pink.png";
import Red from "../../assets/red.png";
import Bandw from "../../assets/bandw.png";

const About = () => {
	return (
		<div className="about">
			<div className="about-overlay"></div>
			<section className="about_intro">
				<h1>About me</h1>
				<div className="row about_intro-details justify-content-between">
					<div className="intro-details1">
						<div>
							<p>Hey there,</p>
							<p>I'm Ajiri (she/her).</p>
							<p>
								I am a multidisciplinary designer (Digital products and UI/UX)
								and I enjoy writing as a
							</p>
							<p>fulfilling pastime.</p>
						</div>
						<div>
							<p>
								I have spent my career gathering extensive experience designing
								applications, websites
							</p>
							<p>
								and software for mobile devices and the web with Companies like
								Jaiz Bank, Kora and
							</p>
							<p> Yellow Card Financial.</p>
						</div>
						<div>
							<p>
								My skills range from user experience design, wireframing,
								prototyping, usability research
							</p>
							<p>
								to user interface design. As a proven team player, I can work
								with teams of different
							</p>
							<p>sizes or as a lone designer.</p>
						</div>
						<p>
							I am an excellent communicator and enjoy thinking critically
							through complex problems.
						</p>
					</div>
					<div className="intro-details2">
						<p className="intro-details2-header">3 things to note</p>
						<div className="intro-details2-white row align-items-center">
							<div></div>
							<p>I absolutely love sports!</p>
							<img src={White} alt="Sports Icon" />
						</div>
						<div className="intro-details2-pink row align-items-center">
							<div></div>
							<p>I am a health and fitness enthusiast</p>
							<img src={Pink} alt="Gym Icon" />
						</div>
						<div className="intro-details2-red row align-items-center">
							<div></div>
							<p>I wanted to join the air force</p>
							<img src={Red} alt="Air Force Icon" />
						</div>
					</div>
				</div>
			</section>
			<section className="about_funFacts">
				<div className="row about_funFacts-cont justify-content-between">
					<p className="funDes">Some Fun Facts;</p>
					<div className="funFacts-details">
					<p className="funMob">Some Fun Facts;</p>
						<div>
							<p>
								I enjoy watching animation spanning across different ages and
							</p>
							<p>
								themes. I would most likely win an award for best disney cartoon
							</p>
							<p>lip syncer if such a competition exists.</p>
						</div>
						<div>
							<p>
								I have been to about 20 local tourism points here in Nigeria and
								once
							</p>
							<p>went to Benin Republic’s Cotonou in 2018 for a city tour.</p>
						</div>
						<div>
							<p>
								I’ve designed products across 7 different industries; Healthcare
								/
							</p>
							<p>
								Telemedicine, Fin-Tech, crypto, Edutech, travel, insurance and
							</p>
							<p>Banking</p>
						</div>
						<div>
							<p>I love love vanilla ice cream, plain and without any added</p>
							<p>sweetening obstacles.</p>
						</div>
						<div>
							<p>
								My happy place would be lying on a rug in a soundproof room,
							</p>
							<p>
								with speakers all around blasting my favourite songs while I
								sing
							</p>
							<p>along at the top of my voice.</p>
						</div>
						<div>
							<p>In my free time I’m;</p>
							<p>
								Speaking with my community of junior African designers,
								increasing
							</p>
							<p>
								access to jobs, encouraging visibility, & supporting with
								resources
							</p>
							<p>needed to scale up.</p>
						</div>
					</div>
					<div className="funFacts-img">
						<div></div>
						<img src={Bandw} alt="Black and White" />
					</div>
				</div>
			</section>
			<section className="row about_experience">
				<div className="experiences">
					<p className="experience-header">Experience.</p>
					<div className="hr"></div>
					<div className="experience-details firsttt">
						<p>
							<b>Product Designer — Yellow Card Financial</b> (August 2019 -
							Date)
						</p>
						<p>Atlanta GA, USA</p>
						<p>Remote, Full Time</p>
					</div>
					<div className="experience-details">
						<p>
							<b>Lead Product Designer — Morena Pixels</b> (January 2021 - Date)
						</p>
						<p>Porto Alegre, Brazil</p>
						<p>Remote, Part Time</p>
					</div>
					<div className="experience-details">
						<p>
							<b>UX/UI Designer — Vela Business Solutions</b> (January 2020 -
							August 2020)
						</p>
						<p>Lagos, Nigeria</p>
						<p>Part Time</p>
					</div>
					<div className="experience-details">
						<p>
							<b>Visual Designer — Young Culture</b> (February 2019 - July
							2019)
						</p>
						<p>Lagos, Nigeria</p>
						<p>Remote, Part Time</p>
					</div>
					<div className="experience-details">
						<p>
							<b>Visual Design Intern — Etalon Studios</b> (July 2017 - Dec
							2017)
						</p>
						<p>Warri, Nigeria</p>
						<p>Full time</p>
					</div>
					<div className="experience-buttonCont funDes">
						<a
							href="AResume.pdf"
							download="Ajiri's Resume"
							className="experience_button"
						>
							Download Resume
						</a>
					</div>
				</div>
				<div className="experience-skills">
					<div className="skill">
						<p className="skill-header">Skills</p>
						<div className="hr"></div>
						<div className="skills-details">
							<p>User Interface Design</p>
							<p>User Experience Design</p>
							<p>User-flow Mapping</p>
							<p>Prototyping</p>
							<p>Service Design</p>
							<p>Wireframing</p>
							<p>User Research</p>
							<p>Usability Testing</p>
							<p>Illustration</p>
						</div>
					</div>
					<div className="tools">
						<p className="skill-header">Tools</p>
						<div className="hr"></div>
						<div className="tool-details">
							<p>Figma</p>
							<p>Sketch</p>
							<p>Miro</p>
							<p>Whimsical</p>
							<p>Google Office Suite</p>
							<p>Adobe XD </p>
						</div>
					</div>
				</div>

					<div className="experience-buttonCont funMob">
						<a
							href="AResume.pdf"
							download="Ajiri's Resume"
							className="experience_button"
						>
							Download Resume
						</a>
					</div>
			</section>
		</div>
	);
};

export default About;
