import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Footer from "./components/Footer/Footer";
import MorenaScreen from "./components/Morena/MorenaScreen";
import MorenaLeaderScreen from "./components/Morena/MorenaLeaderScreen";
import YellowScreen from "./components/Yellowcard/YellowScreen";
import YellowPagaScreen from "./components/Yellowcard/YellowPagaScreen";
import TripzScreen from "./components/Tripzapp/TripzScreen";

function App() {
  useEffect(() => {
    window.addEventListener("load", () => {
      document.querySelector(".preloader").classList.add("fade-out");
      setTimeout(() => {
        document.querySelector(".preloader").style.display = "none";
      }, 600);
    });
  }, []);

  return (
    <div className="app">
      <div className="preloader">
        <div className="box">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <Router>
        <Navbar />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/morena-creators-casestudy">
            <MorenaScreen />
          </Route>
          <Route path="/morena-leaders-casestudy">
            <MorenaLeaderScreen />
          </Route>
          <Route path="/yellowcard-mobile-casestudy">
            <YellowScreen />
          </Route>
          <Route path="/yellowcard-paga-casestudy">
            <YellowPagaScreen />
          </Route>
          <Route path="/tripzapp-casestudy">
            <TripzScreen />
          </Route>
          <Route path="*">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
