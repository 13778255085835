import React from "react";
import { Link } from "react-router-dom";

import "./MorenaScreen.scss";

import Morena3 from "../../assets/morena3.png";
import MorenaP from "../../assets/morenaP.gif";


const MorenaScreen = () => {
	return (
		<div className="morenaScreen">
			<div className="container">
				<div className="morenaScreen-cont">
					<Link className="row morenaScreen-back" to="/">
						<img src="BG.png" alt="Back Button" />
						<p>Back</p>
					</Link>
					<div className="row morenaScreen-details">
						<div className="details-overlay"></div>
						<div className="details-intro">
							<h3>MorenaPixels Creator’s Corner</h3>
							<div>
								<p>
									Building An inclusive and diverse stock media platform focused
									on making stock
								</p>
								<p>content containing people of color more accessible.</p>
							</div>

							<div className="details-img funMob">
							<div className="details-img-overlay"></div>
							<img src={Morena3} alt="Morena Illustration" />
						</div>

							<p>
								<b>See Web App </b>
								<a href="http://creators.morenapixels.com" target="_blank" rel='noopener noreferrer'>
									{" "}
									here
								</a>
							</p>
							<p>
								<b>Project Date:</b> Jan 2021 - Date
							</p>

							<div>
								<p>
									<b>Company:</b> MorenaPixels
								</p>
								<p>
									Working part time as the lead product designer, with a team of
									seven; 3 designers, 4 developers, 1
								</p>
								<p>Project Manager, and 1 Project Owner</p>
							</div>

							<div>
								<p>
									<b>Collaborators</b>
								</p>
								<p>
									Ajiri Omafokpe, Samuel Aneme, Meyene Udoh, Remi Owadokun,
									Samuel Olaegbe
								</p>
							</div>

							<p>
								<b>Platform:</b> Desktop web, Mobile Web
							</p>

							<p>
								<b>My Contribution:</b> Research, conceptualization, UI Design,
								UX design, Project Ownership, prototyping
							</p>
						</div>
						<div className="details-img funDes">
							<div className="details-img-overlay"></div>
							<img src={Morena3} alt="Morena Illustration" />
						</div>
					</div>
				</div>
			</div>
			<section className="morenaScreen-bg bg-1">
				<div className="container row bg-cont">
					<p className="bg-cont-header">Background:</p>
					<div>
						<p>
							Morena Pixels is a Stock Media Platform making a diversity of
							image, video, and
						</p>
						<p>
							audio content of Africa and people of color accessible to the rest
							of the world.
						</p>

						<p className="bg-cont-between">
							Founded in 2021, MorenaPixels aims to have an unmatched, extensive
							selection of
						</p>
						<p>
							different photos and videos from different parts of the world to
							help content
						</p>
						<p>
							creators, experts, designers, thought leaders, journalists, and
							other professionals
						</p>
						<p> across the globe.</p>
					</div>
				</div>
			</section>

			<section className="morenaScreen-bg bg-2">
				<div className="container row bg-cont">
					<p className="bg-cont-header">The challenge:</p>
					<div>
						<p>
							As a Product Designer of Nigerian descent, I struggle to find
							stock content
						</p>
						<p>
							containing people that look like me and places that look like
							where I live. A lot of
						</p>
						<p>
							creators also have this problem. It’s either one or two pictures
							being used
						</p>
						<p>
							everywhere or you have to hire models for whatever scene you want
							to create.
						</p>

						<p className="bg-cont-between">
							This makes creating products and marketing content that can be
							localized to
						</p>
						<p>appeal to the local community very difficult.</p>

						<p className="bg-cont-between">
							Many local video, picture, and audio creators have or can make
							stock worthy
						</p>
						<p>
							content but there is a problem of being compensated properly via
							most of the
						</p>
						<p>
							stock media platforms and most times its not worth it for them.{" "}
						</p>

						<p className="bg-cont-between">
							These are the problems that Morena Pixels is out to solve.
						</p>
					</div>
				</div>
			</section>

			<section className="morenaScreen-bg bg-3">
				<div className="container row bg-cont">
					<p className="bg-cont-header">User Research:</p>
					<div>
						<p>
							For two weeks, we interviewed 10 African creators living in Africa
							to understand
						</p>
						<p>
							their pain points with sharing their content on existing stock
							platforms and how
						</p>
						<p>
							MorenaPixels can help them Mitigate these problems for a better
							user experience.
						</p>

						<p className="bg-cont-between">
							<b>Research questions:</b>
						</p>
						<p>1. What is your experience with existing stock platforms?</p>
						<p>2. How do you currently earn from the content you create?</p>
						<p>
							3. What features would you like to see in an inclusive stock
							platform?
						</p>
						<p>
							4. What would you change in the way existing stock platforms are
							set up?
						</p>
						<p>
							5. What would you retain in the way existing stock platforms are
							set up?
						</p>
						<p>6. How would you like to be paid as you submit content?</p>

						<p className="bg-cont-between">
							<b>Research Takeaways:</b>
						</p>
						<p>During this session we got the following insights:</p>

						<p className="bg-cont-between">
							1. 40% of the participants said that they would like to have
							multiple payment
						</p>
						<p>options, like crypto.</p>
						<p>
							2. 30% of the participants said they would like to choose if they
							want to sell the
						</p>
						<p>content for a one-off fee or get royalties.</p>
						<p>
							3. 20% of the participants wanted there to be quick support for
							when they have
						</p>
						<p>problems using the platform or getting paid.</p>
						<p>
							4. 10% of the participants wanted to be able to take a pay cut so
							their content
						</p>
						<p>
							can be on the front pages when customers search (A pay for ads
							situation).
						</p>
						<p>
							5. 60% of the participants wanted to be able to earn on referrals
							and have some
						</p>
						<p>control over the process</p>
					</div>
				</div>
			</section>

			<section className="morenaScreen-bg bg-4">
				<div className="container row bg-cont">
					<p className="bg-cont-header">Solution:</p>
					<div>
						<p>
							We created a web app to allow creators to upload their content,
							edit their profile
						</p>
						<p>
							and information, see their content usage stats and track their
							referrals and
						</p>
						<p>
							earnings. (A detailed description of my creative process while
							creating this would
						</p>
						<p>be up soon)</p>
						<p>
							Click <a href="http://creators.morenapixels.com" target="_blank" rel='noopener noreferrer'>here</a> to navigate the live Web app.
						</p>

						<img
							className="bg-cont-between morweb"
							src={MorenaP}
							alt="Morena Website Gif"
						/>
					</div>
				</div>
			</section>

			<section className="morenaScreen-bg bg-5">
				<div className="container row bg-cont">
					<p className="bg-cont-header">Reflections:</p>
					<div>
						<p>
							This was a very challenging and exciting project because it is the
							first time I had
						</p>
						<p>
							to lead a product project from start to finish. I also had no
							experience in the
						</p>
						<p>
							industry and it was very exciting to research existing products
							and make things
						</p>
						<p>better.</p>

						<p className="bg-cont-between">
							I learned to listen as a team lead and communicate with clarity. I
							came up with
						</p>
						<p>
							requirements for each page and the acceptance criteria and learned
							to use
						</p>
						<p>ClickUp detailedly.</p>

						<p className="bg-cont-between">
							On this project, we had weekly sprints and made sure the
							developers were
						</p>
						<p>carried along every step of the way.</p>
					</div>
				</div>
			</section>

			<section className="morenaScreen-bg bg-6">
				<div className="container row bg-cont">
					<p className="bg-cont-header">Next Steps:</p>
					<div>
						<p>
							The creator’s corner is in development now and is about 80%
							completed. We
						</p>
						<p>
							already have creators signed up and uploading content. I am
							excited to see the V.1
						</p>
						<p>completely built as soon as possible.</p>
					</div>
				</div>
			</section>
		</div>
	);
};

export default MorenaScreen;
