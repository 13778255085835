import React from "react";
import { Link } from "react-router-dom";

import "./TripzScreen.scss";

import Tripzappp from "../../assets/tripzappp.png";
import Tripzapp from "../../assets/tripzapp.gif";


const TripzScreen = () => {
  return (
    <div className="tripzScreen">
      <div className="container">
        <div className="tripzScreen-cont">
          <Link className="row tripzScreen-back" to="/">
            <img src="BG.png" alt="Back Button" />
            <p>Back</p>
          </Link>
          <div className="row tripzScreen-details">
            <div className="details-overlay"></div>
            <div className="details-intro">
              <h3>Tripzapp Partners Webapp</h3>
              <div>
                <p>
                  Explore amazing African destinations and unique experiences
                  created by local experts.
                </p>
              </div>

              <div className="details-img funMob">
              <div className="details-img-overlay"></div>
              <img src={Tripzappp} alt="Tripzapp Illustration" />
            </div>

              <p>
                <b>Project Date:</b> December 2020 - March 2021
              </p>

              <div>
                <p>
                  <b>Company:</b> Tripzapp
                </p>
              </div>

              <p>
                <b>Platform:</b> Web, Mobile
              </p>

              <div>
                <p>
                  <b>My Contribution:</b> Research, Conceptualization, UI
                  Design, UX design, Project Ownership, prototyping
                </p>
              </div>
            </div>
            <div className="details-img funDes">
              <div className="details-img-overlay"></div>
              <img src={Tripzappp} alt="Tripzapp Illustration" />
            </div>
          </div>
        </div>
      </div>
      <section className="tripzScreen-bg bg-1">
        <div className="container row bg-cont">
          <p className="bg-cont-header">Background;</p>
          <div>
            <p>
              TripZapp is a travel consultancy agency, focusing on the African
              continent and
            </p>
            <p>particularly West Africa.</p>

            <p className="bg-cont-between">
              They create amazing trips tailored specifically to you or your
              group to ensure you
            </p>
            <p>have a great travel experience in Africa without any hassle.</p>

            <p className="bg-cont-between">
              They will also handle your flight and hotel bookings, airport
              transfers and ground
            </p>
            <p>
              transportation as well as tours in a well curated daily travel
              itinerary.
            </p>
          </div>
        </div>
      </section>

      <section className="tripzScreen-bg bg-2">
        <div className="container row bg-cont">
          <p className="bg-cont-header">The Goal;</p>
          <div>
            <p>
              With the current complexity of the travel industry, the people at
              tripzapp wanted
            </p>
            <p>
              to redo their website and turn their amazing business into a
              proper product.
            </p>

            <p className="bg-cont-between">
              Onboarding of partners was mainly done manually without a
              centralised platform
            </p>
            <p>and this made the already cumbersome process even more so.</p>

            <p className="bg-cont-between">
              The goal of the webapp is to make it easier for Partners
              (Tour/housing Agents) to
            </p>
            <p>
              list their experiences or accomodation so that the tourists can
              find them and
            </p>
            <p>book online.</p>
          </div>
        </div>
      </section>

      <section className="tripzScreen-bg bg-3">
        <div className="container row bg-cont">
          <p className="bg-cont-header">Solution;</p>
          <div>
            <p>
            Because we had an existing redesigned and redeveloped webapp to piggyback
            </p>
            <p>
            off, it was easier to draft general requirements for the app. The lead designer
            </p>
            <p>
            majorly handled communications with the shareholders to make sure we weren't
            </p>
            <p>working out of business scope.</p>

            <p className="bg-cont-between">
            Data research on how new users interacted with our current webapp gave us
            </p>
            <p>
            insights into how the UX flows should be modified to accommodate new traders.
            </p>
            <p>We streamlined the sign-up process in order to reduce onboarding drop-off,</p>
            <p>reduced the number of steps required to make individual transactions like making</p>
            <p>deposits or buying coins, and generally made the product more intuitive for new</p>
            <p>traders.</p>

            <p className="bg-cont-between">
            A major part of the app that determined how the rest of the app went and
            </p>
            <p>needed a lot of decision making was the homepage. I would be elucidating how</p>
            <p>we solved this problem in the next few paragraphs.</p>
            <img
              className="bg-cont-between-img tripzz"
              src={Tripzapp}
              alt="Tripzapp Website Gif"
            />
          </div>
        </div>
      </section>

      <section className="tripzScreen-bg bg-6">
        <div className="container row bg-cont">
          <p className="bg-cont-header">Reflections;</p>
          <div>
            <p>
              Because i had to join the project halfway, i appreciated design
              systems even
            </p>
            <p>
              more. They not only help to make the design consistent and make
              design-dev
            </p>
            <p>
              handoff easier, they also made it easier for me to continue on the
              project without
            </p>
            <p>too many hassles.</p>

            <p className="bg-cont-between">
              It is also quite interesting to place side by side the experience
              of being the lone
            </p>
            <p>
              designer with the experience of working within a full product
              team.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TripzScreen;
