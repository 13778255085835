import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Navlogo from "../../assets/Navlogo.svg";

import "./Navbar.scss";

const Navbar = () => {
  useEffect(() => {
    const hamburger = document.querySelector(".hamburger");
    const navMenu = document.querySelector(".navbar_details");
    const closeNavMenu = document.querySelector(".closeMenu");
    const navLink = document.querySelectorAll(".navbar_a");

    hamburger.addEventListener("click", mobileMenu);
    closeNavMenu.addEventListener("click", closeMenu);
    navLink.forEach((n) => n.addEventListener("click", closeMenu));

    function mobileMenu() {
      hamburger.classList.toggle("active");
      navMenu.classList.toggle("active");
    }
    function closeMenu() {
      hamburger.classList.toggle("active");
      navMenu.classList.remove("active");
    }
  }, []);

  return (
    <div className="navbar">
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <Link to="/" className="navbar_logo">
            <img src={Navlogo} alt="Ajiri Oma" />
          </Link>

          <ul className="row navbar_details justify-content-between">
            <div className="closeMenu">
              <div>
                <span className="bar"></span>
                <span className="bar"></span>
              </div>
            </div>
            <li>
              <a className="navbar_a" href="https://www.ajirioma.com" target="_blank" rel="noreferrer">
                 Go to ajirioma.com
              </a>
            </li>
            <li>
              <Link className="navbar_a" to="/about">
                About me
              </Link>
            </li>
            <li>
              <a
                className="navbar_a"
                href="/#live-projects"
              >
                 Live projects
              </a>
            </li>
            <li>
              <a className="navbar_a" href="/#case-studies">
              Case studies
              </a>
            </li>
            <li>
              <a 
                href="AResume.pdf" 
                download="Ajiri's Resume"
                className="navbar_button"
                >
                Download Resume
              </a>
            </li>
          </ul>
          <div className="hamburger">
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
