import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import YellowHand from "../../assets/yellowHand.svg";
import MorenaHand from "../../assets/morenaHand.svg";
import TripzHand from "../../assets/tripzHand.svg";
import MorenaHand2 from "../../assets/morenaHand2.svg";
import YellowHand2 from "../../assets/yellowHand2.svg";
import CircleArrow from "../../assets/circleArrowRight.svg";

import "./Home.scss";
// swiper bundle styles
import "swiper/swiper-bundle.css";

// swiper core styles
import "swiper/swiper-bundle.min.css";

// import Swiper core and required modules
import SwiperCore, { Keyboard, Pagination, Navigation } from "swiper";

SwiperCore.use([Keyboard, Pagination, Navigation]);

const Home = () => {
  return (
    <div className="home">
      <div className="home_overlay"></div>
      <section className="home_intro">
        <h1>Hi, I’m Ajiri Oma</h1>
        <p className="home_intro-desc">
          A digital product and UI/UX designer, passionate about creating
          thoughtful experiences at the intersection of design, business, and
          marketing.
        </p>
        <div className="home_intro-details">
          <p>
            Senior Product designer at Korapay, currently building{" "}
            <a
              href="http://ayagigs.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ayagigs
            </a>
            . Previously worked with{" "}
            <a
              href="https://vela.com.ng"
              target="_blank"
              rel="noopener noreferrer"
            >
              Vela
            </a>{" "}
            and Yellow Card
          </p>
          <img src="Smilee.svg" alt="Smile Emoji" />
        </div>
      </section>
      <section id="case-studies" className="home_caseStudies">
        <h3 className="homeHeader">Case studies</h3>

        <div className="case-wrapper row">
          <Swiper cssMode={true} 
           loop={true}
          navigation={{
            nextEl: '.swipe-custom-button-next'
				}}>
            <SwiperSlide>
              <div className="case-details">
                <h3>Yellow Card</h3>
                <p>Version 1.0 of the Yellow Card mobile app</p>

                <div className="case-details-btns row">
                  <a href="/yellowcard-mobile-casestudy">See case study</a>
                  <span className="swipe-custom-button-next">
                    <p>Next case study</p>
                    <button>
                      <img src={CircleArrow} alt="" />
                    </button>
                  </span>
                </div>
              </div>

              <div className="case-img">
                <img src={YellowHand} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="case-details">
                <h3>MorenaPixels</h3>
                <p>An inclusive and diverse stock media platform 🛠</p>

                <div className="case-details-btns row">
                  <a href="/morena-creators-casestudy">See case study</a>
                  <span className="swipe-custom-button-next">
                    <p>Next case study</p>
                    <button>
                      <img src={CircleArrow} alt="" />
                    </button>
                  </span>
                </div>
              </div>
              <div className="case-img">
                <img src={MorenaHand} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="case-details">
                <h3>Tripzapp</h3>
                <p>Connecting travellers to African experience suppliers 🛠</p>

                <div className="case-details-btns row">
                  <a href="/tripzapp-casestudy">See case study</a>
                  <span className="swipe-custom-button-next">
                    <p>Next case study</p>
                    <button>
                      <img src={CircleArrow} alt="" />
                    </button>
                  </span>
                </div>
              </div>
              <div className="case-img">
                <img src={TripzHand} alt="" />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              {" "}
              <div className="case-details last-2">
                <h3>Yellow Card Paga
Design Process</h3>

                <div className="case-details-btns row">
                  <a href="/yellowcard-paga-casestudy">See case study</a>
                  <span className="swipe-custom-button-next">
                    <p>Next case study</p>
                    <button>
                      <img src={CircleArrow} alt="" />
                    </button>
                  </span>
                </div>
              </div>
              <div className="case-img">
                <img src={YellowHand2} alt="" />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              {" "}
              <div className="case-details last-2">
                <h3>MorenaPixels Design
Leadership Process</h3>

                <div className="case-details-btns row">
                  <a href="/morena-leaders-casestudy">See case study</a>
                  <span className="swipe-custom-button-next">
                    <p>Next case study</p>
                    <button>
                      <img src={CircleArrow} alt="" />
                    </button>
                  </span>
                </div>
              </div>
              <div className="case-img">
                <img src={MorenaHand2} alt="" />
              </div>
            </SwiperSlide>                                                                                                                        
          </Swiper>
        </div>
      </section>

      <section id="live-projects" className="home_liveProjects">
        <h3 className="homeHeader">Some live projects</h3>

        <div className="live-detailsCont row">
          <div className="live-details">
            <h4>Ayagigs</h4>
            <span>
              <p>
                Connecting vetted Web3 freelancers with businesses that need
                them
              </p>
              <a href="https://beta.ayagigs.com/" target="_blank" rel='noopener noreferrer'>Go to product</a>
            </span>
          </div>

          <div className="live-details">
            <h4>Yellow Card</h4>
            <span>
              <p>The easiest way to buy and sell cryptocurrency online</p>
              <a href="https://play.google.com/store/apps/details?id=io.yellowcard.app&hl=en&gl=US" target="_blank" rel='noopener noreferrer'>Go to product</a>
            </span>
          </div>

          <div className="live-details">
            <h4>Wewire Africa</h4>
            <span>
              <p>
                Seamless cross border payments for businesses and high network
                individuals
              </p>
              <a href="https://www.wewireafrica.com/" target="_blank" rel='noopener noreferrer'>Go to product</a>
            </span>
          </div>

          <div className="live-details">
            <h4>Moni Africa</h4>
            <span>
              <p>Community Finance For Africa</p>
              <a href="https://play.google.com/store/apps/details?id=com.rank.moni" target="_blank" rel='noopener noreferrer'>Go to product</a>
            </span>
          </div>

          <div className="live-details">
            <h4>Jaiz Bank</h4>
            <span>
              <p>Nigeria’s premier no interest bank</p>
              <a href="https://play.google.com/store/apps/details?id=com.jaizbank.app&hl=en" target="_blank" rel='noopener noreferrer'>Go to product</a>
            </span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
