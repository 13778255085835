import React from "react";
import { Link } from "react-router-dom";

import "./YellowPagaScreen.scss";
import Yellow3 from "../../assets/yellow3.png";
import BankTransfer from "../../assets/bankTransfer.svg";
import Ideas from "../../assets/ideas.svg";
import PagaDeposit from "../../assets/pagaDeposit.svg";
import PagaPeer from "../../assets/pagaPeer.svg";
import PagaConfirm from "../../assets/pagaConfirm.svg";
import PagaCode from "../../assets/pagaCode.svg";
import PagaPopup from "../../assets/pagaPopup.svg";


const YellowPagaScreen = () => {
  return (
    <div className="yellowPaga">
      <div className="container">
        <div className="yellowPaga-cont">
          <Link className="row yellowPaga-back" to="/">
            <img src="BG.png" alt="Back Button" />
            <p>Back</p>
          </Link>
          <div className="row yellowPaga-details">
            <div className="details-overlay"></div>
            <div className="details-intro">
              <h3>Yellow Card Paga Design Process</h3>
              <div>
                <p>
                  Design flows for Paga integration (Deposits and Withdrawals)
                  in Nigeria.
                </p>
              </div>

              <div className="details-img funMob">
                <div className="details-img-overlay"></div>
                <img src={Yellow3} alt="Yellowcard Illustration" />
              </div>

              <p>
                <a
                  href="https://play.google.com/store/apps/details?id=io.yellowcard.app&hl=en&gl=US"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>Get the App</b>
                </a>
              </p>

              <p>
                <b>Project Date:</b> August 2020 - March 2021
              </p>

              <div>
                <p>
                  <b>Company:</b> Yellow Card Financial
                </p>
                <p className="produ">
                  Working remotely as a product designer, with a team of seven;
                  3 designers, 3 developers, and 1 Project manager
                </p>
              </div>

              <div>
                <p>
                  <b>Collaborators</b>
                </p>
                <p>
                  Great Ndidi, Eniola Abiola, Ajiri Omafokpe, Idorenyin Obong,
                  Peter Esenwa, Joe Okpukoro, Bukunmi Folorunso
                </p>
              </div>

              <p>
                <b>Platform:</b> IOS, Android
              </p>

              <p>
                <b>My Contribution:</b> Visual design,Experience Design,
                Information architecture design
              </p>
            </div>
            <div className="details-img funDes">
              <div className="details-img-overlay"></div>
              <img src={Yellow3} alt="Yellowcard Illustration" />
            </div>
          </div>
        </div>
      </div>

      <section className="yellowPaga-bg bg-1">
        <div className="container row bg-cont">
          <p className="bg-cont-header">Background;</p>
          <div className="solution-wrapper">
            <p>
              Yellow Card is a cryptocurrency exchange startup operating out of
              Africa, with a growing presence in
            </p>
            <p>
              14+countries across Africa. The company’s main value proposition
              is making crypto more accessible
            </p>
            <p>
              in developing countries, and it does this by allowing customers to
              buy crypto with digital payments
            </p>
            <p>on their web and mobile apps.</p>
          </div>
        </div>
      </section>

      <section className="yellowPaga-bg bg-2">
        <div className="container row bg-cont">
          <p className="bg-cont-header">The Goal;</p>
          <div className="solution-wrapper">
            <p>
              Design flows for Paga integration (Deposits and Withdrawals) in
              Nigeria. Paga is a
            </p>
            <p>
              Nigerian payment service provider who facilitates sending &
              receiving money,
            </p>
            <p>
              remittances, buying airtime, & paying bills. They provide Yellow
              Card with a
            </p>
            <p>
              service to broker peer-to-peer payments for Naira deposits &
              withdrawals.
            </p>
          </div>
        </div>
      </section>

      <section className="yellowPaga-bg bg-3">
        <div className="container row bg-cont">
          <p className="bg-cont-header">Constraints;</p>

          <div className="solution-wrapper">
            <ul>
              <li>
                <p>
                  For compliance reasons, the customers should not use crypto
                  related
                </p>
              </li>
            </ul>
            <p>descriptions when making deposits or withdrawals.</p>
            <ul>
              <li>
                <p>
                  The bulk of the payment process will not be carried out in app
                  and that restricts
                </p>
              </li>
            </ul>
            <p>
              {" "}
              the amount of control we have (Design-wise) once the customer is
              redirected
            </p>
            <p>to the browser.</p>
          </div>
        </div>
      </section>

      <section className="yellowPaga-bg bg-4">
        <div className="container ">
          <div className="bg-cont-between screen-img current">
            <p>Current bank transfer deposit flow</p>
            <img src={BankTransfer} alt="Yellowcard Website Image" />
          </div>

          <div className="bg-cont-between-img screen-img">
            <p>Ideas Considered</p>
            <img src={Ideas} alt="Yellowcard Website Image" />
          </div>
        </div>
      </section>

      <section className="yellowPaga-bg bg-5">
        <div className="container row bg-cont">
          <p className="bg-cont-header">Solution;</p>
          <div className="solution-wrapper">
            <div className="solution-cont cont1">
              <p>
                We changed how this is displayed, no more cards, we have simple
                lists now that fit the new brand colors and UI aesthetics. There
                is now a (i) icon that they can click on to see more information
                about the payment method.
              </p>
              <img src={PagaDeposit} alt="paga deposit screen" />
            </div>

            <div className="solution-cont cont2">
              <p>
                We added a modal that shows up for first time transactors. This
                modal outlines all the actions the customer would have to go
                through to successfully deposit. They can trigger this
                information on their own at any time by clicking the (i) icon
                next to the
              </p>
              <img src={PagaPeer} alt="paga deposit popup screen" />
            </div>

            <div className="solution-cont cont2">
              <ul>
                <p className="bolda">
                  We made the following edits to this screen:
                </p>
                <li>
                  <p>
                    “Confirm” in the header copy “confirm” as the button copy
                  </p>
                </li>
                <li>
                  <p>
                    Took out the peer to peer reference, we didnt need it here
                    after all.
                  </p>
                </li>
              </ul>
              <img src={PagaConfirm} alt="paga deposit popup screen" />
            </div>

            <div className="solution-cont cont2">
              <ul>
                <p className="bolda">
                  We made the following edits to this screen:
                </p>
                <li>
                  <p>
                    Took out we found you a match and replaced it with “transfer
                    money from your bank
                  </p>
                </li>
                <li>
                  <p>
                    Took out the tap to copy from their phone number narration
                    pointer because they most likely know their phone number and
                    do not need to copy it.
                  </p>
                </li>
                <li>
                  <p>
                    Decided to no longer threaten them with a failed transaction
                    if they sent the wrong amount because CS people told me that
                    they would get their deposit anyway
                  </p>
                </li>
                <li>
                  <p>
                    We changed the button text to say “done” because we said
                    “do” in the header sub copy.
                  </p>
                </li>
              </ul>
              <img src={PagaCode} alt="paga transfer code screen" />
            </div>

            <div className="solution-cont cont2">
              <ul>
                <p>
                  We placed a confirmation modal for them to confirm that they
                  have done the bank transfer. Sounds like common sense right?
                </p>

                <p className="bolda">P.s</p>

                <p>
                  I asked the data analyst to track these screens and measure if
                  people followed through better, but i left before i could
                  analyze those results
                </p>
              </ul>
              <img src={PagaPopup} alt="paga transfer popup screen" />
            </div>
          </div>
        </div>
      </section>

      <section className="yellowPaga-bg bg-6">
        <div className="container row bg-cont">
          <p className="bg-cont-header">Reflection;</p>
          <div className="disable solution-wrapper">
            <ul>
              <li className="li">
                <p>
                  In finance, the compliance and legal team are your best
                  friends
                </p>
              </li>

              <li className="li">
                <p>
                  Compliance and legal people do not care about the customers
                </p>
              </li>

              <li className="li">
                <p>Compliance and legal people are in too many meetings</p>
              </li>

              <li className="li">
                <p>
                  Engineering should be your best friends (for real this time)
                </p>
              </li>

              <li className="li">
                <p>
                  If you have a UX writer on your team, you have been blessed by
                  the universe.{" "}
                </p>
              </li>

              <li>
                <p className="p1">
                  You are better off carrying out your usability testing at the
                  prototype stage of
                </p>
              </li>
              <p className="p">your design</p>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default YellowPagaScreen;
